<template>
  <div :class="{ 'shrinkreport-content': drawer && !isMobile }" class="mt-16">
    <div>
      <Breadcrumbs class="inlineblock" />
    </div>

    <!-- RIGHT CLICK MENU -->
    <RenameDeleteActions
      v-if="showMenu"
      v-model="showMenu"

      :x="x"
      :y="y"

      :renameAction="renameType"
      :renameName="$t('general.rename')"
      :deleteAction="confirmDelete"
      :deleteName="$t('general.delete')"
    />
    <!-- RIGHT CLICK MENU -->

    <!-- NAMING AND RENAMING TYPE DIALOGS -->
    <NamingDialog
      v-if="isAddingProjectType"
      v-model="isAddingProjectType"

      :headlineName="$t('projecttypes.add_project_type')"
      :typeName="$t('general.name')"

      :cancelName="$t('general.cancel')"                
      :cancelFunction="() => (isAddingProjectType = false)"
      :actionName="$t('general.add')"
      :actionFunction="confirmAddProjectType"
    />
    <NamingDialog
      v-if="isEditingType"
      v-model="isEditingType"

      :baseValue="editedType.name"

      :headlineName="$t('projecttypes.rename_project_type') + ' ' + editedType.name"
      :typeName="$t('general.name')"

      :cancelName="$t('general.cancel')"                
      :cancelFunction="() => (isEditingType = false)"
      :actionName="$t('general.rename')"
      :actionFunction="saveType"
    />
    <!-- NAMING AND RENAMING TYPE DIALOGS -->

    <!-- EDIT PROJECT TYPE TRANSLATION DIALOG -->
    <v-dialog
      v-if="isEditingTranslation"
      v-model="isEditingTranslation"
      persistent
      max-width="600"
    >
      <v-card>
        <v-card-title>
          {{ $t("projecttypes.edit_translation") }}
        </v-card-title>
        <v-card-text>
          <v-text-field
            :label="'Text'"
            outlined
            v-model="editedTranslation.name"
            maxlength="45"
          ></v-text-field>
          <v-select
            :items="availableLanguages"
            label="Languages"
            v-model="editedTranslation.language"
            disabled
            outlined
          ></v-select>
        </v-card-text>
        <v-card-actions>
          <v-btn
            text
            @click="isEditingTranslation = false"
            class="no-transform"
          >
            {{ $t("general.cancel") }}
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            color="green"
            dark
            @click="saveTranslation"
            class="no-transform"
          >
            {{ $t("general.rename") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- EDIT PROJECT TYPE TRANSLATION DIALOG -->

    <!-- PROJECT TYPE LIST -->
    <v-container fluid>
      <v-row>
        <v-col md="3" sm="6" cols="12">
           <v-select
            :items="projectTypesLists"
            :label="$t('projecttypes.list')"
            v-model="selectedProjectTypesList"
            item-text="name"
            item-value="id"
            return-object
            @change="selectedProjectTypeId = -1;"
            filled
            hide-details
          ></v-select>
        </v-col>
      </v-row>
      <v-row>
        <v-col sm="6" cols="12">
          <v-card class="align-center pa-5">
            <v-row v-if="selectedType && selectedProjectTypeId != -1">
              <v-spacer/>
              <v-btn
                rounded
                small
                color="primary"
                dark
                style="text-transform:none"
                @click="renameType"
              >
                {{ $t('general.rename') }}
              </v-btn>
              <v-btn
                style="text-transform:none"
                rounded
                small
                color="red"
                dark
                @click="confirmDelete"
              >
                {{ $t('general.delete') }}
              </v-btn>
            </v-row>
            <v-row>
              <v-subheader>{{ $t("general.projecttypes") }}</v-subheader>
              <v-list dense style="width:100%;max-height:55vh;overflow:auto;" v-if="selectedProjectTypesList">
                <v-list-item
                  v-for="(item, i) in selectedProjectTypesList.projectTypes.filter((pt) => pt.id != -1)"
                  :class="{
                    'active-projecttype': item.id == selectedProjectTypeId,
                  }"
                  :key="i"
                  @click="setSelected(item.id)"
                  @contextmenu="show($event, item)"
                >
                  <v-list-item-content>
                    <v-list-item-title v-text="item.name"></v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-row>
            <v-row>
              <v-btn
              @click="add"
              class="ma-2 add-pt-btn"
              rounded
              color="primary"
              dark
              >{{ $t("projecttypes.add_project_type") }}</v-btn>
            </v-row>
          </v-card>
        </v-col>
        <v-col sm="6" cols="12">
          <v-card class="align-center" v-if="selectedProjectTypeId != -1">
            <v-subheader style="font-size: 0.75rem">{{
              $t("projecttypes.type_translations")
            }}</v-subheader>
            <v-data-table
              :headers="headers"
              :items="selectedProjectTypeTranslations"
              @click:row="editTranslation"
              item-key="index"
              disable-pagination
              hide-default-footer
            >
            </v-data-table>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
    <!-- PROJECT TYPE LIST -->
    <ConfirmDialog
      v-if="confirmDialog"
      v-model="confirmDialog"

      :title="$t('confirm.head')"
      :description="$t('confirm.delete_type')"

      :cancelName="$t('reportInfo.no')"
      :actionName="$t('reportInfo.yes')"
      :actionFunction="deleteType"
    />
  </div>
</template>

<script>
import { mapState, mapActions, mapMutations } from "vuex";

import Breadcrumbs from "@/components/Breadcrumbs";
import RenameDeleteActions from "@/components/actions/RenameDeleteActions.vue";
import NamingDialog from '@/components/dialogs/NamingDialog.vue';
import ConfirmDialog from '@/components/dialogs/ConfirmDialog.vue';
import UserService from "@/services/user.service";

export default {
  name: "ProjectTypes",
  components: {
    Breadcrumbs,
    RenameDeleteActions,
    NamingDialog,
    ConfirmDialog,
  },
  data() {
    return {
      x: 0,
      y: 0,
      allProjectTypes: [],
      isEditingType: false,
      showMenu: false,
      selectedType: null,
      editedType: null,
      selectedProjectTypeId: -1,
      isAddingProjectType: false,
      headers: [
        {
          text: "Name",
          value: "name",
          width: "50%",
        },
        { text: "Language", value: "language" },
      ],
      //
      isEditingTranslation: false,
      editedTranslation: null,
      confirmDialog: false,
      projectTypesLists: [],
      selectedProjectTypesList: null
    };
  },
  computed: {
    ...mapState({
      isMobile: (state) => state.isMobile,
      drawer: (state) => state.drawer,
      availableLanguages: (state) => state.availableLanguages,
    }),
    selectedProjectTypeTranslations() {
      var translations = [];
      try {
        let projectType = this.allProjectTypes.find((pt) => pt.id == this.selectedProjectTypeId);
        var foundTranslations = null;
        if (projectType) {
          foundTranslations = projectType.project_types_translations;
        }

        if (foundTranslations) {
          for (var i = 0; i < this.availableLanguages.length; i++) {
            var translation = foundTranslations.find(
              (t) => t.language == this.availableLanguages[i]
            );
            var id = -1;
            var name = "";

            if (translation) {
              id = translation.id;
              name = translation.name;
            }

            translations.push({
              id: id,
              name: name,
              language: this.availableLanguages[i],
            });
          }
        }
      } catch (err) {
        console.log(err)
      }

      return translations;
    },
  },
  mounted() {
    this.setBreadcrumbs([
      { text: this.$t("general.settings"), href: "/settings" },
      { text: this.$t("general.projecttypes"), href: "/settings/projecttypes" },
    ]);
    this.getProjectTypes(this.$i18n.locale).then(() => {});
  },
  methods: {
    ...mapMutations(['openToast','SET_AVAILABLE_LANGUAGES']),
    ...mapActions({
      setBreadcrumbs: "setBreadcrumbs",
    }),
    setSelected(id) {
      this.selectedType = this.allProjectTypes.find(x => x.id == id);
      this.selectedProjectTypeId = id;
    },
    show(e, item) {
      e.preventDefault();
      this.selectedType = item;
      this.showMenu = false;
      this.x = e.clientX;
      this.y = e.clientY;
      this.$nextTick(() => {
        this.showMenu = true;
      });
    },
    deleteType() {
      this.deleteProjectType(this.selectedType, this.$i18n.locale).then(() => {
        this.setSelected(null);
        this.$forceUpdate();
      });

      this.confirmDialog = false;
    },
    renameType() {
      this.isEditingType = true;
      this.editedType = JSON.parse(JSON.stringify(this.selectedType));

      // Se fait maintenant dans le composant NamingDialog.vue
      //setTimeout(() => {
      //  this.$refs.editedTypeName.focus();
      //}, 250);
    },
    saveType(newTypeName) {
      let changes = {
        changes: {
          projectTypeId: this.editedType.id,
          name: newTypeName,
        },
      };

      this.selectedType.name = newTypeName;

      this.saveProjectType(changes, this.$i18n.locale).then(() => {
        this.isEditingType = false;
      });
    },
    add() {
      this.isAddingProjectType = true;
    },
    confirmAddProjectType(newProjectTypeName) {
      this.isAddingProjectType = false;
      
      this.addProjectType({ projectTypeName: newProjectTypeName, listId: this.selectedProjectTypesList.id }).then(() => {
        let newProjectType = this.allProjectTypes.find(pt => pt.name == newProjectTypeName);
        console.log('newProjectType', newProjectType)
        if (newProjectType) {
          this.setSelected(newProjectType.id);
        }
        this.$forceUpdate();
      });
    },
    editTranslation(translation) {
      this.isEditingTranslation = true;
      this.editedTranslation = JSON.parse(JSON.stringify(translation));
      console.log(translation);
    },
    saveTranslation() {
      let changes = {
        changes: {
          projectTypeId: this.selectedProjectTypeId,
          projectTypeTranslationId: this.editedTranslation.id,
          name: this.editedTranslation.name,
          language: this.editedTranslation.language,
        },
      };

      this.saveProjectTypeTranslation(changes, this.$i18n.locale).then(() => {
        this.isEditingTranslation = false;
        // scope.setSelected();
      });
    },
    confirmDelete() {
      this.confirmDialog = true;
    },
    async getProjectTypes() {
      let response = await UserService.getProjectTypes();
      this.SET_ALL_PROJECT_TYPES(response.data);
    },
    async addProjectType(data) {
      let response = await UserService.addProjectType(data);
      this.SET_ALL_PROJECT_TYPES(response.data);
    },
    async saveProjectType(data) {
      let response = await UserService.saveProjectType(data);
      this.SET_ALL_PROJECT_TYPES(response.data);
    },
    async deleteProjectType(data) {
      try {
        let response = await UserService.deleteProjectType(data);
        this.SET_ALL_PROJECT_TYPES(response.data);
        this.openToast({ message: this.$t("projecttypes.project_type_deleted"), duration: 5000, color: 'green'})
      }
      catch (err) {
        this.openToast({ message: this.$t("projecttypes.project_type_in_use"), duration: 5000, color: 'red'})
      }
    },
    async saveProjectTypeTranslation(data) {
      let response = await UserService.saveProjectTypeTranslation(data);
      this.SET_ALL_PROJECT_TYPES(response.data);
    },
    async saveSupportingPlateTranslation(data){
      let response = await UserService.saveSupportingPlateTranslation(data);
      this.SET_ALL_STANDARD_SUPPORTING_PLATES(response.data);
    },
    SET_ALL_PROJECT_TYPES(data) {
      this.allProjectTypes = data.projecttypes;
      console.log('SET_ALL_PROJECT_TYPES', data)
      if (data.projectTypesLists) {
        this.projectTypesLists = data.projectTypesLists;

        if (this.projectTypesLists.length > 0) {
          this.selectedProjectTypesList = this.projectTypesLists[0];
        }
      }
      if (data.languages) {
        this.SET_AVAILABLE_LANGUAGES(data.languages);
      }

      for (let projectTypesList of this.projectTypesLists) {
        projectTypesList.projectTypes = [];
      }

      for (let projectType of this.allProjectTypes) {
        let projectTypeList = this.projectTypesLists.find(l => l.id == projectType.projectTypesListId);
        if (projectTypeList) {
          projectTypeList.projectTypes.push(projectType);
        }
      }
    },
  },
};
</script>
<style scoped>
.active-projecttype {
  border-radius: 8px;
  background: rgba(0, 159, 77, 0.1);
  color: #009f4d;
}
.add-pt-btn {
  left: calc(50% - 120px);
  text-transform: none;
}
.no-transform {
  text-transform: none;
}
tr {
  cursor: pointer !important;
}
</style> 